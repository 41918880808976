import './style.scss'
import ReactDOM from 'react-dom/client'
import Experience from './Experience'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <div className='page-wrapper'>
        <Experience />
    </div>
)