import { useEffect, useState } from "react";
import PhysicsExp from "./components/threejs/PhysicsExp";
import Portal from "./components/threejs/Portal";
import { Canvas } from '@react-three/fiber'
import Copy from "./components/Copy";
import GalaxyExp from "./components/threejs/GalaxyExp";
import Waves from "./components/threejs/Waves";

export default function Experience()
{

    //mouse position
    const [mousePosition,setMousePosition] = useState({ x: 1, y: 1 });
    const updateMousePosition = ev => {
        let fromCenterX = ev.clientX/window.innerWidth
        let fromCenterY = ev.clientY/window.innerHeight
        setMousePosition({ x: fromCenterX, y: fromCenterY });
    };
    useEffect(()=> {
        window.addEventListener('mousemove', updateMousePosition);
    })

    //window size
    const [windowSize, setWindowSize] = useState({x:window.innerWidth, y:window.innerHeight})
    const updateSize = () => {
        setWindowSize({x:window.innerWidth, y:window.innerHeight})
    }
    window.addEventListener('resize', updateSize)

    //set experience
    const allExperiences = ['portal', 'physics', 'galaxy', 'waves']

    const [experiences, setExperiences] = useState(allExperiences)
    const [whichExperience, setWhichExperience] = useState(experiences[(Math.floor(Math.random() * experiences.length))])

    //shuffles through experiences refreshing when they run out
    const shuffleExperience = () => {
        const expCopy = 
        experiences.length === 1
            ? allExperiences.filter(exp => exp != whichExperience)
            : experiences.filter(exp => exp != whichExperience)
       
        setWhichExperience(expCopy[(Math.floor(Math.random() * expCopy.length))])
        setExperiences(expCopy)
    }

    return <>
        <Copy 
            shuffleExperience={shuffleExperience}
        />
        {whichExperience === 'waves' &&
            <Canvas
                camera={{
                    fov: 9,
                    near: 0.1,
                    far: 200,
                    position: [ 2, 25, 15 ]
                }}
            >
                <Waves
                    mousePosition={mousePosition}
                    windowSize={windowSize}
                />
            </Canvas>
        }
        {whichExperience === 'portal' &&
            <Canvas>
                <Portal 
                    mousePosition={mousePosition}
                    windowSize={windowSize}
                /> 
            </Canvas>
        }
        {whichExperience === 'physics' &&
            <Canvas
            camera={{
                fov: 40,
                near: 0.1,
                far: 200,
                position: [ 0, 0, 9 ]
            }}>
                <PhysicsExp 
                    mousePosition={mousePosition}
                />
            </Canvas>
        }
        {whichExperience === 'galaxy' &&
            <Canvas>
                <GalaxyExp 
                    mousePosition={mousePosition}
                />
            </Canvas>
            
        }
    </>
}