import { useRef, useState } from "react";
import Contact from "./Contact";
import ContactIcon from "./ContactIcon";
import Linkedin from "./Linkedin";
import Mg from "./Mg";
import Shuffle from "./Shuffle";

export default function Copy({shuffleExperience}){

    const shuffleBtn = useRef() 
    const shuffle=()=> {
        shuffleExperience()
        shuffleBtn.current.classList.add('rotate')
        setTimeout(() => {
            shuffleBtn.current.classList.remove('rotate')
        }, 1500);
    }

    return(
<div className='content'>
        {/* <Contact 
            visible={contact}
            toggleModal={toggleModal}
            initalClick={initalClick}
        /> */}
        <Mg />
        <div className="text">
            <h1>Hi I'm Mel</h1>
            <p>I'm a front end developer who enjoys finding creative solutions.</p>
            <div className="shuffle" onClick={shuffle}>
                <div ref={shuffleBtn}>
                    <Shuffle />
                </div>
                <span>Shuffle experience</span>
            </div>
            
        </div>
        <div className="bottom">
            <p>This website is currently being redone. Please enjoy this small experience.</p>
            <div className="buttons">
                {/* <div onClick={toggleModal}>
                    <ContactIcon />
                </div> */}
                <a href="https://www.linkedin.com/in/melissa-gabriele/" target="_blank" rel="noopener noreferrer">
                    <Linkedin />
                </a>
            </div>
        </div>
    </div>
    )
}